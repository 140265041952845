"use client";
import React, { useState } from "react";
import { cn } from "../utils/cn";
import { LabelComponent } from "./ui/label-component";
import { InputBox } from "./ui/input-box";
import { Typography, Button, Grid, Checkbox, FormControlLabel, Modal, Box, Container, useMediaQuery } from "@mui/material";
import { useTheme } from "../contexts/ThemeContext";
import GoogleLoginButton from "../contexts/GoogleLoginButton";
import CloseIcon from '@mui/icons-material/Close';
import InfoModal from "./ui/infoModal";

export function Signing({
    handleSubmit,
    isSignUp,
    isForgotPassword,
    email,
    setEmail,
    password,
    setPassword,
    name,
    setName,
    toggleSignUp,
    toggleForgotPassword,
    termsAccepted,
    setTermsAccepted,
    keepMeSignedIn,
    setKeepMeSignedIn,
    privacyPolicyAccepted,
    setPrivacyPolicyAccepted
}) {
    const [errors, setErrors] = useState({});
    const { theme } = useTheme();
    const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
    const textColor = theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary;
    const buttonBg = theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300];
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    // State to manage the modals
    const [openTermsModal, setOpenTermsModal] = useState(false);
    const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

    const handleGoogleAuth = () => {
        const backendUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
        window.location.href = `${backendUrl}/auth/google`;
    };

    // Modal content
    const termsContent = [
        {
            header: "1. Introduction",
            body: "Welcome to Just Write, a platform operated by Soozh LLC, a Wyoming-based company. These Terms and Conditions govern your use of the Just Write app. By accessing or using our app, you agree to comply with and be bound by these terms."
        },
        {
            header: "2. Acceptance of Terms",
            body: "By registering for and/or using the Just Write app, you agree to these Terms and Conditions and any policies or practices that may be updated from time to time."
        },
        {
            header: "3. User Obligations",
            body: "As a user, you agree to use Just Write responsibly and in compliance with all applicable laws. You are responsible for the content you upload and must not infringe on the intellectual property rights of others."
        },
        {
            header: "4. Intellectual Property",
            body: "All content you upload to Just Write remains your intellectual property. However, by uploading content, you grant Just Write a non-exclusive, worldwide license to use, display, and distribute your content for the purpose of providing our services. Just Write is not responsible for the protection of content you choose to make public."
        },
        {
            header: "5. Privacy Policy",
            body: "Your use of Just Write is also governed by our Privacy Policy, which outlines how we collect, use, and protect your information."
        },
        {
            header: "6. Limitation of Liability",
            body: "Just Write and Soozh LLC are not liable for any damages arising from your use of the app. You agree to indemnify and hold Just Write and Soozh LLC harmless from any claims resulting from your use of the app or your violation of these terms."
        },
        {
            header: "7. Governing Law",
            body: "These terms are governed by and construed in accordance with the laws of the State of Wyoming, USA. Any disputes arising under these terms will be subject to the exclusive jurisdiction of the courts of Wyoming."
        },
        {
            header: "8. Modifications to Terms",
            body: "Just Write reserves the right to modify these Terms and Conditions at any time. We will notify you of any significant changes, and your continued use of the app after such changes constitutes your acceptance of the new terms."
        },
        {
            header: "9. Contact Information",
            body: "If you have any questions or concerns about these Terms and Conditions, please contact us"
        }
    ];

    const privacyContent = [
        {
            header: "1. Introduction",
            body: "Welcome to Just Write, a platform operated by Soozh LLC. This Privacy Policy explains how we collect, use, disclose, and safeguard your information, including intellectual property (IP), when you use the Just Write app. By accessing or using our app, you agree to the terms outlined in this Privacy Policy."
        },
        {
            header: "2. Information We Collect",
            body: "We collect various types of information, including personal data, usage data, and intellectual property information such as the content you upload."
        },
        {
            header: "3. How We Use Your Information",
            body: "We use your information to provide and maintain our services, personalize your experience, communicate with you, and comply with legal obligations."
        },
        {
            header: "4. Intellectual Property Rights and Responsibilities",
            body: "You retain all rights to the IP that you create and upload to Just Write. We are not responsible for protecting content you make public, and you assume all risks associated with making your content public."
        },
        {
            header: "5. Sharing and Disclosure of Information",
            body: "We may share your information with third-party service providers, in business transfers, or to comply with legal requirements."
        },
        {
            header: "6. Data Security",
            body: "We implement reasonable security measures to protect your data and IP from unauthorized access, use, or disclosure, but no system can guarantee absolute security."
        },
        {
            header: "7. Data Retention",
            body: "We retain your data for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law."
        },
        {
            header: "8. User Rights",
            body: "You have the right to access, correct, delete, or restrict your personal data and content. You can also object to the processing of your data for certain purposes."
        },
        {
            header: "9. International Data Transfers",
            body: "Your data may be transferred to and processed in countries outside your country of residence, including the U.S., with appropriate safeguards."
        },
        {
            header: "10. Children's Privacy",
            body: "Just Write is not intended for users under the age of 16, and we do not knowingly collect personal information from children."
        },
        {
            header: "11. Changes to the Privacy Policy",
            body: "We may update this Privacy Policy from time to time, and significant changes will be communicated via email or in-app notifications."
        },
        {
            header: "12. Contact Information",
            body: "If you have any questions or concerns about this Privacy Policy, please contact us"
        },
        {
            header: "13. Legal Basis for Processing",
            body: "Processing of personal data and content is based on your consent, contractual necessity, or legitimate interests."
        },
        {
            header: "14. California Privacy Rights",
            body: "California residents have rights under the California Consumer Privacy Act (CCPA), including the right to know, delete, and opt-out of the sale of personal data and content."
        },
        {
            header: "15. User Consent and Acknowledgment",
            body: "By using Just Write, you acknowledge that you have read and understood this Privacy Policy and agree to its terms, including those related to intellectual property."
        }
        // Add other sections here
    ];

    const onFormSubmit = (e) => {
        e.preventDefault();
        setErrors({});

        if (isSignUp && !termsAccepted) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                termsAccepted: "You must accept the terms and conditions"
            }));
            return;
        }
        handleSubmit(e);
    };

    return (
        <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input" style={{ backgroundColor: bgColor, borderRadius: '20px' }}>
            <h2 className="font-bold text-xl" style={{ color: textColor }}>
                Welcome to Just Write
            </h2>
            <p className="text-sm max-w-sm mt-2" style={{ color: textColor }}>
                Login to unlock your full potential
            </p>

            <form className="my-8" onSubmit={onFormSubmit}>
                {isSignUp && (
                    <LabelInputContainer>
                        <LabelComponent htmlFor="firstname">First name</LabelComponent>
                        <InputBox
                            id="firstname"
                            placeholder="Tyler"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <Typography color="error">{errors.name}</Typography>}
                    </LabelInputContainer>
                )}
                <LabelInputContainer className="mb-4">
                    <LabelComponent htmlFor="email">Email Address</LabelComponent>
                    <InputBox
                        id="email"
                        placeholder="projectmayhem@fc.com"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <Typography color="error">{errors.email}</Typography>}
                </LabelInputContainer>
                {!isForgotPassword && (
                    <>
                        <LabelInputContainer className="mb-4">
                            <LabelComponent htmlFor="password">Password</LabelComponent>
                            <InputBox
                                id="password"
                                placeholder="••••••••"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <Typography color="error">{errors.password}</Typography>}
                        </LabelInputContainer>
                        {!isSignUp && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={keepMeSignedIn}
                                        onChange={(e) => setKeepMeSignedIn(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography variant="body2" style={{ color: textColor, padding: '10px 0px' }}>
                                        Keep me signed in (2 Weeks)
                                    </Typography>
                                }
                                sx={{ margin: 2 }}
                            />
                        )}
                    </>
                )}

                {/* Checkbox for terms and conditions */}
                {isSignUp && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={termsAccepted}
                                onChange={(e) => setTermsAccepted(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <Typography variant="body2" style={{ color: textColor, padding: '10px 0px' }}>
                                I accept the <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOpenTermsModal(true)}>Terms and Conditions</span> and <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOpenPrivacyModal(true)}>Privacy Policy</span>.
                            </Typography>
                        }
                        sx={{ margin: 2 }}
                    />
                )}
                {errors.termsAccepted && <Typography color="error">{errors.termsAccepted}</Typography>}

                <Button
                    className="relative group/btn block w-full h-10 font-medium rounded-md"
                    type="submit"
                    style={{
                        backgroundColor: buttonBg,
                        color: theme.palette.getContrastText(buttonBg),
                        boxShadow: theme.shadows[1]
                    }}
                >
                    {isSignUp ? 'Register' : isForgotPassword ? 'Reset Password' : 'Sign In'} &rarr;
                    <BottomGradient />
                </Button>
                <Grid container spacing={1}>
                    {!isForgotPassword && (
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                fullWidth
                                variant="outlined"
                                onClick={toggleSignUp}
                                sx={{ mt: 1 }}
                            >
                                {isSignUp ? 'Sign In' : 'Sign Up'}
                            </Button>
                        </Grid>
                    )}
                    {!isSignUp && (
                        <Grid item xs={12}>
                            <Button
                                type="button"
                                fullWidth
                                variant="outlined"
                                onClick={toggleForgotPassword}
                                sx={{ opacity: 0.8 }}
                            >
                                {isForgotPassword ? 'Sign In' : 'Forgot Password'}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography>Or</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleLoginButton />
                    </Grid>
                </Grid>
            </form>

            {/* Terms and Conditions Modal */}
            <InfoModal
                open={openTermsModal}
                onClose={() => setOpenTermsModal(false)}
                title="Terms and Conditions - Just Write"
                content={termsContent}
            />

            {/* Privacy Policy Modal */}
            <InfoModal
                open={openPrivacyModal}
                onClose={() => setOpenPrivacyModal(false)}
                title="Privacy Policy - Just Write"
                content={privacyContent}
            />
        </div>
    );
}

const BottomGradient = () => {
    return (
        <>
            <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
            <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
        </>
    );
};

const LabelInputContainer = ({ children, className }) => {
    return (
        <div className={cn("flex flex-col space-y-2 w-full", className)}>
            {children}
        </div>
    );
};


