import React, { useEffect, useState } from 'react'; // Import useEffect
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { LoginProvider, useLogin } from './contexts/LoginContext'; // Use the login context
import Navbar from './components/Navbar';
import RedirectWithParams from './components/RedirectWithParams.js';
import LandingPage from './components/LandingPage';
import UserProfile from './components/UserProfile';
import PrivateRoute from './components/PrivateRoute';
import { AxiosProvider, useAxios } from './contexts/AxiosContext'; // Import AxiosProvider and useAxios
import VerifyEmail from './components/VerifyEmail';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import { WelcomeUserPage } from './components/WelcomeUserPage';
import AdminDashboard from './components/AdminConsole/AdminDashboard';
import NetworkGraph from './Playground/NetworkGraph';
import SocialGraph from './Playground/SocialGraph';
import ProfileFormComponent from './components/ProfileForm/ProfileFormComponent';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import UserEvents from './components/UserEventsComponents/UserEvents.js';
import ConsentPage from "./components/legal/ConceptPage.js"
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import TermsAndCondition from './components/legal/TermsAndCondition';
import Operations from './components/Operations/Operations.js';
import { PublicView } from './components/PublicView.js'
import AppleInstallDrawer from './components/ui/AppleInstallDrawer'; // Ensure this is correctly imported

const AppContent = () => {
    const { isLoggedIn, logout, pubProfileLink, globalRoles } = useLogin();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [showNavbar, setShowNavbar] = useState(true);
    const [isStandalone, setIsStandalone] = useState(false);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isAppleDevice, setIsAppleDevice] = useState(false);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsAppleDevice(/iphone|ipad|ipod/.test(userAgent));
        setIsStandalone(window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone);

        const isMobileDevice = /mobile/.test(userAgent) || (window.innerWidth <= 768);
        if (isMobileDevice && !isStandalone) {
            setDrawerOpen(true);
        }

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            // Since we are handling this in the drawer, no need to change visibility here
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                }
                setDeferredPrompt(null);  // Clear the prompt after handling it
            });
        }
    };

    useEffect(() => {
        const excludeNavbarPaths = [
            '/test', '/text', '/signup', '/forgot-password', '/admin', // Adjusted paths
            '/reset-password', '/verify', '/flipwordsdemo', '/backgroundBeamsDemo'
        ];

        // Check for exact root path ("/") separately to avoid false positives
        const isExcludedPath = location.pathname === '/' ||
            excludeNavbarPaths.some(path => location.pathname.startsWith(path)) ||
            /^\/profile\/[^/]+$/.test(location.pathname);

        setShowNavbar(!isExcludedPath);  // Set `showNavbar` to false if it's an excluded path
    }, [location.pathname]);




    const navbarHeightDesktop = '10vh';
    const navbarHeightMobileTop = '60px'; // height of the top navbar on mobile
    const navbarHeightMobileBottom = '70px'; // height of the bottom navbar on mobile

    return (
        <>
            <Navbar isLoggedIn={isLoggedIn} pubProfileLink={pubProfileLink} logout={logout} />

            <Box
                sx={{
                    marginTop: isMobile ? navbarHeightMobileTop : navbarHeightDesktop,
                    marginBottom: !showNavbar && isMobile ? navbarHeightMobileBottom : '0px',
                }}
            >
                <Routes>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/redirect" element={<RedirectWithParams />} />
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/network" element={<NetworkGraph />} />
                    <Route path="/socialgraph" element={<SocialGraph />} />
                    <Route path='/p/:pubProfileLink' element={<PublicView />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/verify/:token" element={<VerifyEmail />} />
                    <Route path="/events" element={<UserEvents />} />
                    <Route path="/text" element={<WelcomeUserPage />} />
                    {/* Protected Operations route with role-based access */}
                    <Route
                        path="/operations"
                        element={<PrivateRoute element={<Operations />} roles={['organization_admin', 'system_admin']} />}
                    />
                    <Route
                        path="/admin"
                        element={<PrivateRoute element={<AdminDashboard />} roles={['system_admin']} />}
                    />
                    <Route
                        path="/profile/:userId"
                        element={<PrivateRoute element={<UserProfile />} />}
                    />
                    <Route path="/buildprofile"
                        element={<PrivateRoute element={<ProfileFormComponent />} />} />

                    <Route path="/consent" element={<ConsentPage />} />
                    <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Box>
        </>
    );
};

function App() {
    return (
        <AxiosProvider>
            <Router>
                <ThemeProvider>
                    <LoginProvider>
                        <AppContent />
                    </LoginProvider>
                </ThemeProvider>
            </Router>
        </AxiosProvider>
    );
}

export default App;
