"use client";
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { useLogin } from '../contexts/LoginContext';
import { useTheme } from '../contexts/ThemeContext';
import axios from 'axios';
import {
    Box, Typography, IconButton, Snackbar, Alert,
    TextField, Drawer, useMediaQuery, Grid, Button, Link
} from "@mui/material";
import { BackgroundBeams } from "./ui/background-beams";
import { Signing } from './Signing';
import { cn } from '../utils/cn';
import { AnimatedTextSVG } from './ui/textSVG';

import { WelcomeUserPage } from './WelcomeUserPage'; // Import the WelcomeUserPage component
import ResetPassword from './ResetPassword';
import { useAxios } from '../contexts/AxiosContext';
import { RiAiGenerate } from "react-icons/ri";
import { TiPointOfInterest } from "react-icons/ti";
import { TfiWrite } from "react-icons/tfi";
import { GrTasks } from "react-icons/gr";
import { FiLink } from "react-icons/fi";
import { FaIndianRupeeSign } from "react-icons/fa6";


const loginSchema = z.object({
    email: z.string().email(),
    password: z.string().min(6, { message: "Password must be at least 6 characters long" })
});

const signUpSchema = z.object({
    name: z.string().min(1, { message: "Name is required" }),
    email: z.string().email({ message: "Invalid email address" }),
    password: z.string().min(6, { message: "Password must be at least 6 characters long" })
});

const forgotPasswordSchema = z.object({
    email: z.string().email({ message: "Invalid email address" })
});

const LandingPage = () => {
    //for referral redirect 

    const location = useLocation();
    const [utmSource, setUtmSource] = useState(null);
    const [referral, setreferral] = useState(null);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [keepMeSignedIn, setKeepMeSignedIn] = useState(false)
    const [errors, setErrors] = useState({});
    const [isSignUp, setIsSignUp] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const { isLoggedIn, login, error, logout, userId, username, googleLogin } = useLogin();
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const bgColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper;
    const textColor = theme.palette.text.primary;
    const words = ["interesting", "bold", "beautiful", "modern"];
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const [alert, setAlert] = useState({ message: '', severity: '' });
    const axios = useAxios()
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerContent, setDrawerContent] = useState(''); // To distinguish between 'Sign In' and 'Sign Up'
    const gridSpacing = isMobile ? 2 : 4;
    const [termsAccepted, setTermsAccepted] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setUtmSource(params.get("utm_source")); // Extract specific parameters
        setreferral(params.get("referral"));
    }, [location.search]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            // Store token in localStorage
            localStorage.setItem('token', token);

            // Automatically log in the user with the token
            googleLogin(token);

            // Clean up URL by removing token from query params (optional)
            navigate(location.pathname, { replace: true });
        }
    }, [location, googleLogin, navigate]);


    useEffect(() => {
        if (error) {
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: error, severity: 'error' });
            }, 100);
        }
    }, [error]);

    const handleLogin = async (event) => {
        event.preventDefault();
        setErrors({});

        const validation = loginSchema.safeParse({ email, password });

        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((err) => {
                newErrors[err.path[0]] = err.message;
            });
            setErrors(newErrors);
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: 'Invalid login details', severity: 'error' });
            }, 100);
            return;
        }

        const loginSuccess = await login(email, password, '', keepMeSignedIn);
        if (loginSuccess) {
            navigate('/welcome');
        }
    };

    const handleSignUp = async (event) => {
        event.preventDefault();
        setErrors({});

        const validation = signUpSchema.safeParse({ name, email, password });

        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((err) => {
                newErrors[err.path[0]] = err.message;
            });
            setErrors(newErrors);
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: 'Please correct the errors in the form', severity: 'error' });
            }, 100);
            return;
        }

        try {
            const res = await axios.post(`/auth/register`, {
                name,
                email,
                password,
                termsAccepted,
                termsVersion: "1.0",
                privacyPolicyVersion: "1.0",
                utmSource,        // Make sure this is defined
                referralId: referral // Make sure 'referral' is correctly defined
            });
            if (res.status === 200) {
                setAlert({ message: '', severity: '' });
                setTimeout(() => {
                    setAlert({ message: 'Registration successful! Please check your email to verify your account.', severity: 'success' });
                }, 100);
                setName('');
                setEmail('');
                setPassword('');
                setTermsAccepted(false);  // Reset the checkbox states
                setIsSignUp(false);
                navigate('/');
            }
        } catch (err) {
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                if (err.response?.status === 400) {
                    if (err.response.data.message === 'Referral program has expired.') {
                        setAlert({ message: 'The referral program has expired.', severity: 'error' });
                    } else if (err.response.data.message === 'Referral program has been terminated.') {
                        setAlert({ message: 'The referral program has been terminated.', severity: 'error' });
                    } else if (err.response.data.msg === 'User already exists') {
                        setAlert({ message: 'User already exists.', severity: 'error' });
                    } else if (err.response.data.msg === 'You must accept the terms and conditions and the privacy policy to register') {
                        setAlert({ message: 'You must accept the terms and privacy policy.', severity: 'error' });
                    } else {
                        setAlert({ message: err.response?.data?.msg || 'Error occurred during registration', severity: 'error' });
                    }
                } else {
                    setAlert({ message: 'An unexpected error occurred during registration.', severity: 'error' });
                }
            }, 100);
        }
    };

    const toggleSignUp = () => {
        setIsSignUp(!isSignUp);
        setIsForgotPassword(false);  // Ensure only one form is active at a time
    };

    const toggleForgotPassword = () => {
        setIsForgotPassword(!isForgotPassword);
        setIsSignUp(false);  // Ensure only one form is active at a time
    };

    const handleForgotPassword = async (event) => {
        event.preventDefault();
        setErrors({});

        const validation = forgotPasswordSchema.safeParse({ email });

        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((err) => {
                newErrors[err.path[0]] = err.message;
            });
            setErrors(newErrors);
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: 'Invalid email address', severity: 'error' });
            }, 100);
            return;
        }

        try {
            const res = await axios.post(`/auth/forgot-password`, { email });
            if (res.status === 200) {
                setAlert({ message: '', severity: '' });
                setTimeout(() => {
                    setAlert({ message: 'Password reset link sent! Please check your email.', severity: 'success' });
                }, 100);
                setEmail('');
                setIsForgotPassword(false);
            }
        } catch (err) {
            setAlert({ message: '', severity: '' });
            setTimeout(() => {
                setAlert({ message: err.response?.data?.msg || 'Error occurred while requesting password reset', severity: 'error' });
            }, 100);
        }
    };

    const subHeaderStyle = {
        color: textColor,
        fontWeight: '800',
        fontSize: isMobile ? '14px' : '18px',
        display: 'inline-flex',
        alignItems: 'center'
    };
    const typographyStyle = {
        color: textColor,
        fontWeight: '300',
        fontSize: isMobile ? '12px' : '14px',
    };

    const handleDrawerOpen = (content) => {
        setDrawerContent(content);
        setDrawerOpen(true);
    };

    if (isLoggedIn) {
        return <WelcomeUserPage />;
    }

    return (
        <div style={{
            // minHeight: '100vh', // Ensure the div is at least 100% of the viewport height
            width: '100%',
            // backgroundColor: theme.palette.background.default,
            backgroundColor: 'transparent',
            backgroundRepeat: 'repeat',  // Ensure the pattern repeats
            display: 'flex',
            flexDirection: 'column', // Column layout for stacking content vertically
            justifyContent: 'center',
            alignItems: 'center',
            position: isMobile ? 'relative' : 'absolute',
            overflowY: isMobile ? 'hidden' : 'hidden', // Enable vertical scrolling if content exceeds viewport height
            paddingBottom: isMobile ? '60px' : '0', // Add padding to make room for buttons on mobile
        }}>

            {!isMobile && (
                <>
                    <BackgroundBeams />
                </>
            )}
            <Box
                sx={{
                    width: '100%',
                    height: isMobile ? '10vh' : '15vh',
                    backgroundColor: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '10px',
                    position: 'fixed', // Changed from 'absolute' to 'fixed' to fix it to the top
                    top: 0,
                    left: 0,
                    zIndex: 1, // Set zIndex to 1 to ensure it's above other elements
                }}
            >
                <AnimatedTextSVG text="JustWrite" fontSize="30px" />
                {/* Flexbox that grows and pushes the buttons to the right */}
                <Box sx={{ flexGrow: 1 }} />
                {!isMobile && (
                    <>
                        <Button
                            variant="outlined"
                            sx={{
                                color: theme.palette.common.white
                            }}
                            onClick={() => handleDrawerOpen('Sign In')}
                        >
                            Sign In
                        </Button>
                        <Typography variant='body2' sx={{ margin: '0px 10px' }}>
                            Or
                        </Typography>
                        <Button
                            variant="outlined"
                            sx={{
                                marginRight: '40px',
                                color: theme.palette.common.white
                            }}
                            onClick={() => handleDrawerOpen('Sign Up')}
                        >
                            Sign Up
                        </Button>
                    </>
                )}
            </Box>
                <Grid
                    item
                    xs={12}
                    sx={{ marginTop: '40px', textAlign: 'center', position: 'relative', zIndex: 0 }}
                >
                    <iframe
                        src="https://www.youtube.com/embed/AzJPmtvnx28?autoplay=1&rel=0"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        style={{ width: '80vw', height: isMobile ? '45vw' : '25vw' }} // Adjust height for a 16:9 aspect ratio
                    ></iframe>
                </Grid>
            <Box
                sx={{
                    maxWidth: '80%',
                    margin: 'auto',
                    marginTop: isMobile? '1vh' : '10vh',  // Offset by the height of the navbar
                    height: isMobile ? 'calc(100vh - 15vh)' : 'calc(100vh - 50vh)',  // Full viewport height minus the navbar height
                    overflowY: 'hidden',  // Allow vertical scrolling
                    padding: isMobile ? '0' : '0',  // Optional padding adjustment for mobile
                }}
            >
                <Grid
                    container
                    spacing={gridSpacing}
                    justifyContent="left"
                    sx={{ overflow: 'auto' }}
                >
                    <Grid item xs={12}>
                        <Typography variant={isMobile ? "h5" : "h4"} sx={{ fontWeight: isMobile ? '700' : '800', }} gutterBottom>
                            Features
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom sx={subHeaderStyle}>
                            <FaIndianRupeeSign style={{ marginRight: '8px' }} />
                            Free for All Writers
                        </Typography>
                        <Typography variant="body2" sx={typographyStyle}>
                            Build your writer profile in just 5 minutes. Showcase your skills and experience effortlessly and for free.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom sx={subHeaderStyle}>
                            <RiAiGenerate style={{ marginRight: '8px' }} />
                            AI-Powered Poster Generation
                        </Typography>
                        <Typography variant="body2" sx={typographyStyle}>
                            Generate visually appealing, AI-powered posters for your stories. Enhance your content effortlessly, making your stories more engaging.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom sx={subHeaderStyle}>
                            <FiLink style={{ marginRight: '8px' }} />
                            Single-Link Sharing
                        </Typography>
                        <Typography variant="body2" sx={typographyStyle}>
                            Share your full profile and stories with a single link. Streamline how you present your work to others.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom sx={subHeaderStyle}>
                            <TiPointOfInterest style={{ marginRight: '8px' }} />
                            Attract Interest
                        </Typography>
                        <Typography variant="body2" sx={typographyStyle}>
                            Boost your stories' visibility and attract your audience. Optimize your profile to draw the right readers.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom sx={subHeaderStyle}>
                            <GrTasks style={{ marginRight: '8px' }} />
                            Showcase Work Experience
                        </Typography>
                        <Typography variant="body2" sx={typographyStyle}>
                            Showcase your writing journey and experience. Present your background professionally to stand out.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" gutterBottom sx={subHeaderStyle}>
                            <TfiWrite style={{ marginRight: '8px' }} />
                            Turn Writing into a Profession
                        </Typography>
                        <Typography variant="body2" sx={typographyStyle}>
                            Turn your passion for writing into a career path. Use your profile to demonstrate your professional potential.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
            >
                <Box
                    sx={{
                        width: isMobile ? '100vw' : '50vw', // Full width for mobile, half for desktop
                        padding: 2,
                        backgroundColor: theme.palette.background.default,
                        height: isMobile ? 'auto' : '100%', // Auto height for mobile, full height for desktop
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    {drawerContent === 'Sign In' && (
                        <Signing
                            handleSubmit={isSignUp ? handleSignUp : isForgotPassword ? handleForgotPassword : handleLogin}
                            isSignUp={isSignUp}
                            isForgotPassword={isForgotPassword}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            keepMeSignedIn={keepMeSignedIn}
                            setKeepMeSignedIn={setKeepMeSignedIn}
                            setPassword={setPassword}
                            name={name}
                            setName={setName}
                            errors={errors}
                            toggleSignUp={toggleSignUp}
                            toggleForgotPassword={toggleForgotPassword}
                            termsAccepted={termsAccepted}
                            setTermsAccepted={setTermsAccepted}
                        />
                    )}
                    {drawerContent === 'Sign Up' && (
                        <Signing
                            handleSubmit={handleSignUp}
                            isSignUp={true}
                            isForgotPassword={isForgotPassword}
                            email={email}
                            setEmail={setEmail}
                            password={password}
                            setPassword={setPassword}
                            name={name}
                            setName={setName}
                            errors={errors}
                            toggleSignUp={toggleSignUp}
                            toggleForgotPassword={toggleForgotPassword}
                            termsAccepted={termsAccepted}
                            setTermsAccepted={setTermsAccepted}
                        />
                    )}
                    <Snackbar
                        open={Boolean(alert.message)}
                        autoHideDuration={6000}
                        onClose={() => setAlert({ message: '', severity: '' })}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <Alert onClose={() => setAlert({ message: '', severity: '' })} severity={alert.severity}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                </Box>
            </Drawer>
            {isMobile && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: theme.palette.background.default,
                        padding: '50px 10px',
                        // paddingBottom: '100px',
                        display: 'flex',
                        justifyContent: 'space-around',
                        boxShadow: '0px -2px 10px rgba(0,0,0,0.1)',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            color: theme.palette.common.white,
                        }}
                        onClick={() => handleDrawerOpen('Sign In')}
                    >
                        Sign In
                    </Button>
                    <Typography variant='body2' sx={{ color: theme.palette.common.white }}>
                        Or
                    </Typography>
                    <Button
                        variant="outlined"
                        sx={{
                            color: theme.palette.common.white,
                        }}
                        onClick={() => handleDrawerOpen('Sign Up')}
                    >
                        Sign Up
                    </Button>
                </Box>
            )}
            <Box
                component="footer"
                sx={{
                    position: 'relative', // Not fixed, moves with content
                    bottom: 0,
                    width: '100%',
                    padding: '10px',
                    textAlign: 'center',
                    backgroundColor: 'black',
                    color: '#ffffff',
                    zIndex: -100
                }}
            >
                <Typography variant="body2" sx={{ color: "#ffffff" }}>
                    By using this site, you agree to our{' '}
                    {/* <Link href="/terms-and-conditions" color="white">
                        Terms and Conditions
                    </Link>{' '}
                    and{' '} */}
                    <Link href="/privacy-policy" sx={{ color: "#ffffff" }}>
                        Privacy Policy
                    </Link>.
                </Typography>
            </Box>


        </div>
    );
};

export default LandingPage;
