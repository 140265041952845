import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Link, IconButton, Avatar, Tooltip, Button, Dialog, DialogActions, FormControlLabel, Checkbox,
  DialogContent, DialogContentText, DialogTitle, Snackbar, TextField, Typography
} from '@mui/material'; import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Import the profile icon
import ClearIcon from '@mui/icons-material/Clear';
import { useAxios } from '../../contexts/AxiosContext'
import { useLogin } from '../../contexts/LoginContext'

const baseURL = process.env.REACT_APP_HOSTED_DOMAIN || 'https://justwrite.soozh.app/';

const UsersDataGrid = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState({ id: '', name: '', isAdmin: false });
  const [deleteConfirm, setDeleteConfirm] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const axios = useAxios()
  const { userId } = useLogin()
  const [users, setUsers] = useState([])
  const [dataChangeTrigger, setDataChangeTrigger] = useState(0);
  const [roleSelections, setRoleSelections] = useState({
    system_admin: false,
    organization_admin: false
  });

  const handleOpenDialog = (user) => {
    setCurrentUser(user);
    setRoleSelections({
      system_admin: user.globalRoles.includes('system_admin'),
      organization_admin: user.globalRoles.includes('organization_admin')
    });
    setOpenDialog(true);
  };


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/admin/users');
        // Add a serial number to each user
        const usersWithSNo = response.data.map((user, index) => ({
          ...user,
          sno: index + 1 // Start the serial number from 1
        }));
        setUsers(usersWithSNo);
      } catch (error) {
        console.error('Failed to fetch users', error);
        // Handle error appropriately in your app
      }
    };
    fetchUsers();
  }, [axios, dataChangeTrigger]);

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`/admin/user/${currentUser.id}`);
      if (response.status === 200) {
        setSnackbar({ open: true, message: 'User deleted successfully' });
        setDataChangeTrigger(prev => prev + 1);  // Trigger a data refresh
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbar({ open: true, message: 'Failed to delete user' });
    } finally {
      setOpenDialog(false);
    }
  };

  const handleUpdateRoles = async () => {
    try {
      const newRoles = Object.keys(roleSelections).filter(role => roleSelections[role]);
      const response = await axios.post('/admin/update-privileges', {
        userId: currentUser.id,
        newRoles: newRoles.length > 0 ? newRoles : ['user'],  // Default to 'user' if no boxes checked
        reqUserId: userId
      });

      // Check for different status codes and display appropriate message
      if (response.status === 200) {
        // Success response
        setSnackbar({ open: true, message: response.data });
      } else {
        // Handle other statuses that are considered successful (like 204 No Content)
        setSnackbar({ open: true, message: 'Action completed with no content returned' });
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error updating user privileges:', error.response.data);
        setSnackbar({ open: true, message: error.response.data });
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Error updating user privileges:', error.request);
        setSnackbar({ open: true, message: 'No response from server' });
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error updating user privileges:', error.message);
        setSnackbar({ open: true, message: 'Error sending request' });
      }
    } finally {
      setOpenDialog(false);
    }
    // setSnackbar({ open: true, message: 'Feature Not yet avaiable' });
  };


  const columns = [
    { field: 'sno', headerName: 'SNo', width: 70 },
    {
      field: 'profile_path',
      headerName: 'Profile',
      width: 100,
      renderCell: (params) => (
        <Avatar src={params.value || "path_to_default_image.png"} alt="Profile" />
      ),
    },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'verified',
      headerName: 'Verified',
      width: 120,
      renderCell: (params) => (
        params.value ? <CheckIcon color="success" /> : <ClearIcon color="error" />
      ),
    },
    {
      field: 'globalRoles',
      headerName: 'Global Roles',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.value?.join(', ')}</Typography> // Display roles as a comma-separated string
      )
    },
    {
      field: 'referralInfo',
      headerName: 'Referral',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.value?.join(', ')}</Typography> // Display roles as a comma-separated string
      )
    },
    {
      field: 'pubProfileLink',
      headerName: 'Profile Link',
      width: 200,
      renderCell: (params) => (
        <Link href={`${baseURL}p/${params.value}`} target="_blank">View Profile</Link>
      ),
    },
    { field: 'createdAt', headerName: 'Joined On', width: 150 },
    {
      field: 'edit',
      headerName: 'Edit',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        // Assume userId is available in your component's state or context
        if (params.row._id === userId) {
          return (
            <Tooltip title="You">
              <IconButton>
                <AccountCircleIcon color="primary" />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Edit User">
              <IconButton onClick={() => handleOpenDialog({
                id: params.id,
                name: params.row.name,
                isAdmin: params.row.isAdmin,
                globalRoles: params.row.globalRoles
              })}>
                <EditIcon color='primary'/>
              </IconButton>
            </Tooltip>
          );
        }
      },
    }
  ];

  // Check for duplicates and log them
  const uniqueUsers = users?.reduce((acc, user) => {
    if (!acc.some(u => u._id === user._id)) {
      acc.push(user);
    } else {
      console.warn('Duplicate key found:', user._id);
    }
    return acc;
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={uniqueUsers}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        getRowId={(row) => row._id}  // Ensure unique keys
        components={{ Toolbar: GridToolbar }}
        disableColumnMenu={false}  // Enable column menu
        disableSelectionOnClick  // Disable row selection
      />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Actions</DialogTitle>
        <DialogContent>

          <DialogContentText>Update user roles:</DialogContentText>
          {['system_admin', 'organization_admin'].map((role) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={roleSelections[role]}
                  onChange={(event) => setRoleSelections({
                    ...roleSelections,
                    [role]: event.target.checked
                  })}
                />
              }
              label={role.charAt(0).toUpperCase() + role.slice(1).replace('_', ' ')}
              key={role}
            />
          ))}
          <DialogContentText>To delete this user, type the username to confirm.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Username Confirmation"
            type="text"
            fullWidth
            variant="outlined"
            value={deleteConfirm}
            onChange={(e) => setDeleteConfirm(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateRoles} >
            Update Roles
          </Button>
          <Button onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} disabled={deleteConfirm !== currentUser.name}>
            Delete User
          </Button>
        </DialogActions>

      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </div>
  );
};

export default UsersDataGrid;
